<template>
    <ModalSide class="post-modal" ref="modal">
        <header v-if="is_loading" class="post-head">
            <i class="close" @click="close">
                <svg-icon name="arrow-next" />
            </i>
        </header>

        <header class="post-head" v-else>
            <i class="close" @click="close">
                <svg-icon name="arrow-next" />
            </i>
            <div class="user_info" @click.stop="goProfile(anchor_creator_id)">
                <figure class="avatar">
                    <img v-if="post_data.user_profile" :src="post_data.user_profile.avatar_full_url"
                        @error="imageError($event)" />
                    <img v-else src="@/assets/images/user.png" />
                </figure>
                <div class="title">
                    <h2>{{ post_data.user_profile.nickname }}</h2>
                    <p v-if="post_data">@{{ post_data.social_post.social_handle.replace('@', '') }}</p>
                </div>
            </div>
            <time v-if="post_data && orgin_state">Last updated {{ !post_data.social_post.is_exist && post_data.social_post.social_post_type=='story' ? `: Insights Complete`:daysAgo(post_data.social_post.updated_at) }}</time>
        </header>

        <Loading v-if="is_loading" class="static" />

        <div :class="['inner-box inner-box-st-box']" :key="post_id" v-else>
            <div :class="['left-side', !$store.state.user_data.is_motom_internal_user && 'left-side-100']">
                <figure>
                    <i class="figure-icon"><svg-icon
                            :name="post_data.social_post.social_platform === 'tikTok' ? 'tiktok-pure' : 'instagram'" /></i>
                    <img v-if="post_data.social_post" :src="post_data.social_post.thumbnail_url" />
                    <!-- <Badage v-if="badageText" :value="badageText" :type="'black'" :border="'4'" /> -->
                    <!-- v-if="post_data.social_post.is_brand_post" : -->
                    <div :key="tags_key">
                        <hr />
                        <dl>
                            <dt :class="[whichPost.story && 'story-tag-drow']">
                                <span>Content Tag</span>
                                <i v-if="post_data.social_post.tags.length !== 0 && $store.state.user_data.contract_status"><svg-icon
                                        name="more"  @click="$refs.pop.open(false);" /></i>
                            </dt>
                            <dd v-if="post_data.social_post.tags.length === 0 && $store.state.user_data.contract_status">
                                <!-- add-i  -->
                                <i class="btn add-i" @click="$refs.pop.open(false);"><svg-icon name="plus" /> Content Tag</i>
                            </dd>
                            <dd v-if="post_data.social_post.display_anchor_post_tag">
                                <span class="tag">In Storefront</span>
                            </dd>
                            <dd v-for="(item, index) in post_data.social_post.tags" :key="index">
                                <span class="tag">{{ item.text }}</span>
                            </dd>
                            <DropdownHashtag 
                                :tags="tags" :otherTag="otherTag" :selected_tags="post_data.social_post.tags" :no_all="true"
                                @select-tag="selectTag" ref="pop" :is_anchor_post="post_data.social_post.is_anchor_post===1"/>
                        </dl>
                        <div class="bottom" >
                            <p class="status bottom-line" :class="{ 'error': !post_data.social_post.is_public,'unLive': !post_data.social_post.is_exist &&  post_data.social_post.social_post_type ==='story'}">
                                <span>Post status</span>
                            </p>
                            
                            <p class="bottom-line">
                                <span>Posted date:</span>
                                <time>{{ moment(post_data.social_post.date).format('MMM DD, YYYY') }}</time>
                            </p>
                            
                            <div class="bottom-line content-line" v-if="post_data.social_post.content">
                                <span>Caption</span>
                                <div class="text">
                                    <h3 v-if="is_more && !is_show_more"
                                        v-html="post_data.social_post.content.substring(0, text_length) + '...'"></h3>
                                    <h3 v-else v-html="post_data.social_post.content"></h3>
                                    <a v-if="is_more" @click="is_show_more = !is_show_more">{{ is_show_more ? 'See less' : 'See more' }}</a>
                                </div>
                            </div>

                            <div class="bottom-line content-line" v-if="post_data.social_post.hashtags && post_data.social_post.hashtags.length !== 0">
                                <span>#Hashtags</span>
                                <dl v-if="post_data.social_post.hashtags && post_data.social_post.hashtags.length !== 0">
                                    <dd v-for="(item, index) in post_data.social_post.hashtags" :key="index">
                                        <span>#{{ item.text }}</span>
                                    </dd>
                                </dl>
                            </div>

                            <div class="bottom-line content-line" v-if="post_data.social_post.mentions && post_data.social_post.mentions.length !== 0">
                                <span>@Mentions</span>
                                <dl v-if="post_data.social_post.mentions && post_data.social_post.mentions.length !== 0">
                                    <dd v-for="(item, index) in post_data.social_post.mentions" :key="index">
                                        <span>@{{ item.text }}</span>
                                    </dd>
                                </dl>
                            </div>

                            <a class="btn" v-if="!whichPost.story" :href="post_data.social_post.media_link" target="_blank">View
                                original post</a>
                        </div>
                    </div>
                </figure>

                <div class="content">
                    <div class="top" v-if="whichPost.story">
                        <h3>Instagram stories 24 hour Insights</h3>
                        <div class="desc" v-if="orgin_state">The data below may take up to 48 hours to populate and will not include
                            insights from a creator's Instagram Highlights.</div>
                    </div>

                    <div class="top" v-else>
                        <h3>Insights</h3>
                        <div class="desc" v-if="orgin_state" >Insights available 7 days after content posted. Data updated daily after.</div>
                    </div>

                    <div class="hr" v-if="!orgin_state"></div>
                    <div v-if="orgin_state">

                        <!-- ---------------bottom------------------- -->
                        <div class="bottom info info-ig-story" v-if="post_data.social_post.social_platform !== 'instagram' || (post_data.social_post.social_platform === 'instagram' && social_account_connect_status !== 10)">
                            <ul>
                                <li v-for="(v, k) in whichPost.story ? story_ig_value : post_value" :key="k" v-show="v.show">
                                    <strong>{{ v.value }}</strong>
                                    <span>{{ v.name }}</span>
                                </li>
                            </ul>
                        </div>

                        <!-- storefront add in 9215-->
                        <div v-if="post_data.anchor_post_statistic">
                            <div class="top flex">
                                <h3>In Storefront</h3>
                                <time v-if="post_data">Last updated {{ daysAgo(post_data.anchor_post_statistic.updated_at) }}</time>
                            </div>

                            <div class="storefront-info">
                                <div class="info-title">
                                    <div class="title-left">
                                        <h4>{{ post_data.anchor_post_statistic.name }}</h4>
                                        <div class="desc">{{ post_data.anchor_post_statistic.description }}</div>
                                        <div class="date-time">Date added: <span class="date-value">Jun 19, 2023</span></div>
                                        
                                        <div class="badage-box">
                                            <Badage v-for="(v, k) in post_data.anchor_post_statistic.badage" :key="k" :value="v.name"
                                                :type="v.color" />
                                        </div>
                                    </div>
                                    <div class="title-right">
                                        <div class="svg-box" @click.stop="openCollectionProduct()">
                                            <svg-icon name="bag"/>
                                            <span>{{ post_data.anchor_post_statistic.product_count? post_data.anchor_post_statistic.product_count:'0' }}</span>
                                            <i class="arrow"><svg-icon  name="arrow"/></i>
                                        </div>
                                    </div>
                                </div>

                                <div class="info info-ig-story info-content">
                                    <ul>
                                        <li v-for="(v, k) in storefront_value" :key="k" v-show="v.show">
                                            <strong>{{ v.value }}</strong>
                                            <span>{{ v.name }}</span>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div v-else class="empty-box">
                        <p class="empty">Social data unavailable based on lack of creator permissions. </p>
                    </div>
                </div>
            </div>

            <!-- not sure when will we see here -->
            <div class="right-content" :class="{ 'is-loading': is_loading_data }"
                v-if="$store.state.user_data.is_motom_internal_user && !whichPost.story">
                <div class="top">
                    <h3>Insights</h3>
                    <!-- <DatePicker @update-date="updateDate" :date_range="date_range"
                        :maxDate="need_han_date ? $store.state.user_data.contract_end_time : null"
                        :minDate="need_han_date ? $store.state.user_data.contract_start_time : null" /> -->
                </div>
                <div class="info">
                    <ul>
                        <li :class="{ 'actived': type === 1 }" @click="updateChart(1)">
                            <strong v-if="statistics.engagement || statistics.engagement === 0">{{Number(statistics.engagement).toLocaleString('en-US')}}</strong>
                            <strong class="no-data" v-else>---</strong>
                            <span>Engagements</span>
                        </li>

                        <!-- views -->
                        <li :class="{ 'actived': type === 2 }" @click="updateChart(2)" v-if="whichPost.img && post_data.social_post.social_post_type !=='video'" >
                            <strong v-if="statistics.impressions">{{ Number(statistics.impressions).toLocaleString('en-US')
                                }}</strong>
                            <strong class="no-data" v-else>---</strong>
                            <span>Impressions</span>
                        </li>

                        <li :class="{ 'actived': type === 2 }" @click="updateChart(2)" v-else>
                            <strong v-if="statistics.views">{{ Number(statistics.views).toLocaleString('en-US')
                                }}</strong>
                            <strong class="no-data" v-else>---</strong>
                            <span>{{post_data.social_post.social_post_type === 'reel' ? 'Plays': whichPost.img && post_data.social_post.social_post_type==='video'? 'Video Views': 'Views'}}</span>
                        </li>
                        <!-- views -->

                        <li :class="{ 'actived': type === 3 }" @click="updateChart(3)">
                            <strong v-if="statistics.likes">{{ Number(statistics.likes).toLocaleString('en-US')
                                }}</strong>
                            <strong class="no-data" v-else>---</strong>
                            <span>Likes</span>
                        </li>
                        <li :class="{ 'actived': type === 4 }" @click="updateChart(4)">
                            <strong v-if="statistics.comments">{{ Number(statistics.comments).toLocaleString('en-US')
                                }}</strong>
                            <strong class="no-data" v-else>---</strong>
                            <span>Comments</span>
                        </li>
                        <li :class="{ 'actived': type === 5 }" @click="updateChart(5)">
                            <strong v-if="statistics.shares">{{ Number(statistics.shares).toLocaleString('en-US')
                                }}</strong>
                            <strong class="no-data" v-else>---</strong>
                            <span>Shares</span>
                        </li>
                        <li :class="{ 'actived': type === 6 }" @click="updateChart(6)">
                            <strong v-if="statistics.saved">{{ Number(statistics.saved).toLocaleString('en-US')
                                }}</strong>
                            <strong class="no-data" v-else>---</strong>
                            <span>Saved</span>
                        </li>
                    </ul>

                </div>
            </div>
            <!-- {{ post_data }} -->
        </div>
    </ModalSide>
    <Modal :is_show="is_show_modal" @click-bg="is_show_modal = false" class="hashtag-modal">
        <header class="tag-header">Update Tags <i @click="is_show_modal = false"><svg-icon name="close" /></i>
        </header>
        <Hashtag :only_tag="true" />
    </Modal>
</template>
<script>
import Loading from '@/components/ui/Loading.vue';
import ModalSide from '@/components/ui/ModalSide.vue';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import NoData from '@/components/creator/NoData.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import moment from 'moment';
import CreatorBigCard from '@/components/CreatorBigCard.vue';
import Hashtag from '../Hashtag.vue';
import Modal from '../ui/Modal.vue';
import TagDropdown from '../ui/TagDropdown.vue';
import formatDateDaysAgo from '@/utils/formatDateDaysAgo';
import { HandleNumSpec, HandleNum, makeCollectionBadage } from '@/utils/SwitchFun';
import DropdownHashtag from '@/components/DropdownHashtag.vue';
import Badage from '@/components/ui/Badage.vue';
// import * as echarts from 'echarts';

export default {
    name: 'ProductModalSide',

    components: {
        ModalSide,
        Loading,
        Scrollbar,
        NoData,
        DatePicker,
        CreatorBigCard,
        TagDropdown,
        Hashtag,
        Modal,
        DropdownHashtag,
        Badage,
    },

    data() {
        return {
            type: 1,
            is_loading: false,
            is_loading_data: false,
            is_show_modal: false,
            start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD'),
            post_id: '',
            date_range: {
                start_date: '',
                end_date: '',
            },
            tags: [],
            otherTag: {
                hastags: [],
                instagram_mentions:[],
                tiktok_mentions:[],
            },
            post_data: [],
            user_info: {},
            anchor_creator_id: 0,
            ambassador_user_application_record_id: 0,
            statistics: {},
            is_show_more: false,
            is_more: false,
            api_name: '/creator/get-social-post-statistic',
            text_length: 125,
            post_value:[
                { name: 'Impressions', value: 23,field:'impressions',show:true },
                { name: 'Views', value: 23,field:'views',show:true },
                { name: 'Reach', value: '---', field:'reach',show:true },
                { name: 'Engagements', value: '---',field:'engagement',show:true },
                { name: 'Engagement Rate', value: 23,field:'engagement_rate',show:true },
                { name: 'Likes', value: 23,field:'likes',show:true },
                { name: 'Comments', value: 23,field:'comments',show:true },
                { name: 'Shares', value: 23,field:'shares',show:true },
                { name: 'Saves', value: 23,field:'saved',show:true },
            ],
            story_ig_value: [
                { name: 'Impressions', value: 23,field:'impressions',show:true  },
                { name: 'Reach', value: '---', field:'reach',show:true  },
                { name: 'Engagements', value: '---',field:'engagement',show:true  },
                { name: 'Engagement Rate', value: 23,field:'engagement_rate',show:true  },
                // { name: 'Likes', value: 23,field:'likes' },
                // { name: 'Comments', value: 23,field:'comments' },
            ],
            whichPost: {},
            badageText: {},
            storefront_value:[
                { name: 'Collection Views', value: 23,field:'views',show:true  },
                { name: 'Visits', value: 23,field:'visit',show:true  },
                { name: 'Product Clicks', value: 23,field:'product_click',show:true  },
                { name: 'Sales', value: 23,field:'sales',show:true  },
                { name: 'Sales Value', value: 23,field:'sales_value',show:true  },
            ],
            tags_key:0,
            social_account_connect_status:0,
            orgin_state:true,
            anchor_creator_id:null
        }
    },

    computed: {
        need_han_date() {
            return this.$store.state.user_data.contract_status && this.post_data.social_post.is_brand_post
        },
    },

    methods: {
        moment,
        handleNumSpec: HandleNumSpec,
        handleNum: HandleNum,

        imageError(event) {
            event.target.src = require('@/assets/images/user.png');
        },

        selectTag(item) {
            if (this.post_data.social_post.tags.filter((o) => o.text === item.text).length !== 0) {
                const index = this.post_data.social_post.tags.findIndex(tag => tag.id === item.id);
                this.post_data.social_post.tags.splice(index, 1);
            } else {
                this.post_data.social_post.tags.push(item);
            }

            const tag_ids = [];
            this.post_data.social_post.tags.forEach((v) => {
                tag_ids.push(v.id);
            });

            this.$ajax(`creator/update-social-post-tag`, {
                method: 'post',
                data: {
                    post_ids: [this.post_id],
                    tag_ids: tag_ids,
                }
            });

            this.EventBus.emit('update-post-tags', {
                post_id: this.post_id,
                tags: this.post_data.social_post.tags,
            });
        },

        editTag(obj) {
            this.is_show_modal = true;
        },

        getTags() {
            if (this.$store.state.user_data) {
                this.$ajax(`creator/get-social-post-filter`, {
                    method: 'get',
                }).then(res => {
                    const data = res.data.data;
                    if (!data) return;

                    this.tags = data.social_post_tag_texts;
                    console.log('this.tags',this.tags)
                    this.otherTag.hastags = data.social_post_hashtag_texts;
                    this.otherTag.instagram_mentions= data.social_post_instagram_mention_texts;
                    this.otherTag.tiktok_mentions=data.social_post_tiktok_mention_texts;
                    this.tags_key+=1;
                });
            }
        },

        daysAgo(date) {
            return formatDateDaysAgo(date);
        },

        open() {
            // this.getUserInfo();
            this.getPostData();
            // this.getInfo();
            this.$refs.modal.open();
        },

        close() {
            this.$refs.modal.close();
        },

        makeSta(sta){
            if (this.whichPost.story) {
                    this.story_ig_value.forEach(item => {
                        if (item.field === 'engagement_rate') {
                            item.value = HandleNumSpec(sta[item.field]) !== '---' ? `${HandleNumSpec(sta[item.field])}%` : HandleNumSpec(sta[item.field]);
                        } else {
                            item.value = HandleNum(sta[item.field]);
                        }
                    });
                } else {
                    this.post_value.forEach(item=>{
                        if (item.field === 'engagement_rate') {
                            item.value = HandleNumSpec(sta[item.field]) !== '---' ? `${HandleNumSpec(sta[item.field])}%` : HandleNumSpec(sta[item.field]);
                        } else {
                            item.value = HandleNum(sta[item.field]);
                        }
                    })

                    if(this.whichPost.img && this.post_data.social_post.social_post_type !=='video'){
                        this.post_value[1].show=false;
                        this.post_value[0].show=true;
                    }else{
                        this.post_value[1].show=true;
                        this.post_value[0].show=false;
                        if(this.post_data.social_post.social_post_type==='reel'){
                            this.post_value[1].name='Plays';
                        }else if(this.whichPost.img && this.post_data.social_post.social_post_type==='video'){
                            this.post_value[1].name='Video Views';
                        }else{
                            this.post_value[1].name='Views';
                        }
                    }
                    this.statistics = sta;
                }
        },

        makeStoreSta(sta){
            this.storefront_value.forEach(item => {
                if (item.field === 'sales_value') {
                    item.value = `$${HandleNum(sta[item.field])}`
                } else {
                    item.value = HandleNum(sta[item.field]);
                }
            });
        },

        getPostData() {
            this.is_loading = true;
            const api = `${this.api_name}?social_post_id=${this.post_id}&start_date=${this.start_date}&end_date=${this.end_date}${this.ambassador_user_application_record_id ? '&ambassador_user_application_record_id=' + this.ambassador_user_application_record_id : ''}`;
            this.$ajax(api, {
                method: 'get',
            }).then(res => {
                this.post_data = res.data.data;
                this.makeSta(res.data.data.current_statistic[0]);
                console.log('this.post_data',this.post_data)
                if(res.data.data.anchor_post_statistic){
                    this.makeStoreSta(res.data.data.anchor_post_statistic);
                    makeCollectionBadage(res.data.data.anchor_post_statistic, this);
                }

                this.origin_state = 
                    this.post_data.social_post.social_platform !== 'instagram' || 
                    (
                        this.post_data.social_post.social_platform === 'instagram' && 
                        this.social_account_connect_status !== 10
                    ) ||
                    (
                        this.post_data.social_post.social_platform === 'instagram' && 
                        this.social_account_connect_status === 10 && this.post_data.anchor_post_statistic
                    );
                this.is_loading = false;
            });
        },

        updateChart(type) {
            if (this.type !== type) this.type = type;
        },

        updateDate(obj) {
            this.start_date = moment(obj.start_date).format('YYYY-MM-DD');
            this.end_date = moment(obj.end_date).format('YYYY-MM-DD');
            this.date_range.start_date = this.start_date;
            this.date_range.end_date = this.end_date;
            this.is_loading_data = true;
            const api = `${this.api_name}?social_post_id=${this.post_id}&start_date=${this.start_date}&end_date=${this.end_date}${this.ambassador_user_application_record_id ? '&ambassador_user_application_record_id=' + this.ambassador_user_application_record_id : ''}`;
            this.$ajax(api, {
                method: 'get',
            }).then(res => {
                this.post_data = res.data.data;

                this.is_loading_data = false;
                this.makeSta(res.data.data.current_statistic[0]);
                if(res.data.data.anchor_post_statistic){
                    this.makeStoreSta(res.data.data.anchor_post_statistic);
                    makeCollectionBadage(res.data.data.anchor_post_statistic, this);
                }

                // this.updateChart(this.type);
            });
        },

        openCollectionProduct(){
            let hanldeData=JSON.parse(JSON.stringify(this.post_data.anchor_post_statistic));
            hanldeData.userProfile= this.post_data.user_profile;
            this.EventBus.emit('open-collect-product-modal', { data: hanldeData , data_info: null, date_range: this.date_range });
        },

        goProfile(id){
            let data={
                id:id,
                user_id:id
            }
            this.close();         
            if(this.$store.state.creator_data_open){
                this.EventBus.emit('update-modal-creator',data)
            }else{
                setTimeout(() => {
                    this.EventBus.emit('open-creator-modal',data);
                },100);
            }
             
        }
    },

    mounted() {
        this.getTags();

        this.EventBus.on('updated-all-post-tag', (tags) => {
            if (this.post_data && this.post_data.social_post && this.post_data.social_post.tags) {
                this.post_data.social_post.tags.forEach((tag, tag_index) => {
                    const index = tags.findIndex((o) => o.id === tag.id);
                    if (index === -1) {
                        // remove this tag
                        this.post_data.social_post.tags.splice(tag_index, 1);
                    }
                });

            }
            this.tags = tags;
            this.getTags();
        });

        this.EventBus.off('open-post-modal');
        this.EventBus.on('open-post-modal', (obj) => {

            this.post_id = obj.post.id;
            this.anchor_creator_id = obj.post.anchor_creator_id;
            this.is_more = obj.post.content.length > this.text_length;
            this.ambassador_user_application_record_id = obj.ambassador_user_application_record_id || 0;
            this.api_name = this.ambassador_user_application_record_id ? '/ambassador/get-application-user-record-detail/social-post-statistic' : '/creator/get-social-post-statistic';
            this.social_account_connect_status=obj.post.social_account_connect_status;
            this.anchor_creator_id=obj.anchor_creator_id;

            this.whichPost = obj.whichPost;
            this.badageText = obj.badageText;

            if (obj.date_range) {
                console.log(obj.date_range)
                this.start_date = obj.date_range.start_date;
                this.end_date = obj.date_range.end_date;
                this.date_range = obj.date_range;
            } else {
                this.start_date = moment().subtract(30, 'days').format('YYYY-MM-DD');
                this.end_date = moment().format('YYYY-MM-DD');
                this.date_range = {
                    start_date: this.start_date,
                    end_date: this.end_date,
                }
            }
            this.getTags();
            this.open();
        });
    },

    created() {
        window.addEventListener('resize', this.resizeChart);
    },

    destroyed() {
        window.removeEventListener('resize', this.resizeChart);
    },
}
</script>
<style lang="scss">
.post-modal {
    width: 100%;
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;

    .tag-drop {
        margin-right: 8px;

        .edit-button {
            margin: 6px 8px;
        }

    }

    .post-head {
        display: flex;
        align-items: center;
        height: 40px;

        .close {
            cursor: pointer;
            margin-right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            width: 32px;
            border-radius: 4px;

            &:hover {
                background: var(--bg-02);
            }

            svg {
                width: 16px;
                height: 16px;
                fill: var(--black-90);
                transform: rotate(-180deg);
            }
        }

        .title {
            line-height: 1.1;
            position: relative;
            top: -2px;

            h2 {
                font: var(--font-demi-20);
                color: var(--black-90);
            }

            p {
                color: var(--black-90);
                font-size: 14px;
            }
        }

        .avatar {
            padding-right: 10px;
            height: 32px;

            img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                border: 1px solid var(--black-40);
            }
        }

        .user_info{
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 8px;
            &:hover{
                background-color: var(--bg-08);
            }
        }

        time {
            font: var(--font-med-12);
            color: var(--black-50);
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }


    }

    .empty-box{
        height: 120px;
        display: flex;
        align-items: center;
        padding: 72px 48px;
        
        .empty{
            margin: auto;
            text-align: center;
            font-size: 12px;
            color: var(--black-50);
        }
    }

    .hr {
        border-top: 1px solid var(--border);
        overflow: hidden;
        height: 0px;
        border-bottom: 0;
        margin-top:24px;
    }

    .inner-box {
        display: flex;
        min-height: calc(100vh - 120px);
        margin: 8px 0 0;
    }

    .left-side {
        width: 100%;
        padding: 10px;
        //background-color: var(--bg-10);
        display: flex;

        .content {
            flex: 1;
            padding-left: 24px;
        }

        figure {
            position: relative;
            width: 300px;

            img {
                display: block;
                width: 100%;
                height: auto;
            }

            .figure-icon {
                position: absolute;
                top: 0;
                left: 0;
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--bg-01);
                border-radius: 0 0 10px 0;

                svg {
                    width: 14px;
                    height: 14px;
                    fill: var(--black-90);
                }
            }
            
        }

        time {
            margin: 8px;
            font-size: 12px;
            color: var(--black-50);
        }

        .text {
            margin: 8px;

            a {
                color: var(--blue);
                font-size: 12px;
                cursor: pointer;
            }
        }

        h3 {
            font: var(--font-demi-12);
            color: var(--black-90);
        }

        .btn {
            display: inline-block;
            margin: 8px 0;
            padding: 3px 6px;
            border: 1px solid var(--black-90);
            color: var(--black-90);
            font: var(--font-med-12);
            text-align: center;
            border-radius: 6px;
            cursor: pointer;

            svg {
                fill: var(--black-90);
                width: 8px;
                height: 8px;
                margin-right: 4px;
            }

            &:hover {
                border-color: var(--dark-blue);
                color: var(--dark-blue);
            }
        }

        hr {
            border-top: 1px solid var(--border);
            overflow: hidden;
            height: 0px;
            border-bottom: 0;
        }

        dl {
            display: flex;
            flex-wrap: wrap;
            position: relative;

            dt {
                margin-bottom: 4px;
                font: var(--font-med-12);
                color: var(--black-50);
                width: 100%;
                display: flex;
                align-items: center;
                position: relative;

                &.story-tag-drow{    
                    .item-box {
                        transform: unset !important;
                        top:22px;
                    }
                }

                .ui-dropdown--inner {
                    position: absolute;
                    right: 0;
                    top: 0;

                    &>.ui-button {
                        opacity: 0;
                        width: 100px;
                    }

                    .item-box {
                        right: 0px;
                        transform: translate(108%, -60%);
                    }
                }

                span {
                    flex: 1;
                }


            }

            i {
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                cursor: pointer;
                background-color: white;
                border: 1px solid var(--black-50);
                color: var(--black-50);
                font: var(--font-med-12);

                svg {
                    width: 16px;
                    height: 16px;
                    fill: var(--black-50);
                }

                &:hover {
                    border-color: var(--dark-blue);
                    color: var(--dark-blue);

                    svg {
                        fill: var(--dark-blue);
                    }
                }
            }

            dd {
                display: inline-block;
                margin-right: 8px;
                margin-bottom: 4px;
                font: var(--font-med-12);
                color: var(--black-90);
                position: relative;

                .tag {
                    display: inline-block;
                    padding: 4px 6px;
                    border-radius: 4px;
                    color: var(--black-90);
                    font: var(--font-med-12);
                    background-color: var(--bg-03);
                    font: var(--font-med-12);
                }

                .ui-dropdown--inner {
                    position: absolute;
                    left: 0;
                    top: 0;

                    &>.ui-button {
                        opacity: 0;
                        width: 100px;
                    }

                    .item-box {
                        left: 60px;
                        transform: translateY(-50%);
                    }
                }

                i {
                    width: auto;
                    padding: 0 4px;
                }

                .add-i {
                    display: flex;
                    margin: 0;
                    height: unset;
                    background-color: unset;
                }
            }
        }

        .status {
            display: flex;
            align-items: center;
            margin: 8px;

            span {
                font: var(--font-med-12);
                color: var(--black-90);
                flex: 1cap;
            }

            &:after {
                content: 'Live';
                font: var(--font-med-12);
                color: var(--green);
            }

            &.error:after {
                content: 'Unavilable';
                color: var(--red);
            }

            &.unLive:after {
                content: 'Archived';
                color: var(--black-75);
            }
        }

        .bottom-line{
            color:var(--black-90);
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 8px 0;

            time{
                color:var(--black-90) !important;
                margin: 0;
                font:var(--font-med-12);
            }
            span{
                font:var(--font-med-12);
                color:var(--black-50);
            }

            &.content-line{
                flex-wrap: wrap;
                .text{
                    margin: 2px 0 0 0;
                    width: 100%;
                }

                dl{
                    width: 100%;
                    margin: 2px 0 0 0;
                    span{
                        color:var(--black-90);
                    }
                }
            }
        }

    }

    .dropdown-hashtag{
        bottom:-40px;
        left: 40px;
        top:unset;
    }

    .inner-box-st-box {

        .top {
            margin: 8px;

            .flex{
                justify-content: space-between;
            }

            h3 {
                font: var(--font-demi-20);
                color: var(--black-90);
                flex: 1;
            }

            .desc {
                color: var(--black-75);
                font-size: 12px;
            }
        }


    }



    .right-content {
        flex: 1;
        display: none;

        &.is-loading {
            opacity: .5;
            pointer-events: none;
        }

        .top {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            h3 {
                font: var(--font-demi-20);
                color: var(--black-90);
                flex: 1;
            }

            .desc {
                color: var(--black-75);
                font-size: 12px;
            }
        }

        .chart {
            width: auto;
            box-sizing: content-box;
            height: 300px;
            padding-bottom: 60px;
        }

    }

    .info {
        border: 1px solid var(--border);
        border-radius: 10px;
        padding: 4px;

        &>ul {
            display: flex;

            li {
                width: calc(25% - 8px);
                margin: 4px;
                border-radius: 8px;
                padding: 12px;
                border: 1px solid var(--border);
                cursor: pointer;
                transition: all .2s ease-in-out;

                strong {
                    font: var(--font-med-20);
                    color: var(--dark-blue);
                    display: block;
                    margin-bottom: 4px;
                    height: 20px;
                    line-height: 20px;

                    &.no-data {
                        color: var(--black-50);
                        font-size: 14px;
                    }
                }

                span {
                    font: var(--font-med-14);
                    color: var(--black-75);
                    display: block;
                }

                &:hover {
                    border-color: var(--dark-blue);
                }

                &.actived {
                    border-color: var(--dark-blue);
                }
            }
        }
    }

    .info-ig-story {
        border: unset;
        padding: 0;
        margin-top: 16px;

        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 8px;

            li {
                width: unset;
                margin: 0;
            }
        }
    }

    .storefront-info{
        padding: 8px;
        border:solid 1px var(--border);
        border-radius: 8px;
    }

    .info-title{
        display: flex;
        
        .title-left{
            flex:1;

            h4{
                font:var(--font-demi-14);
                margin-bottom: 8px;
            }

            .desc{
                font:var(--font-med-14);
                color:var(--black-50);
                margin-bottom: 8px;
            }

            .date-time{
                font:var(--font-med-12);
                color:var(--black-50);
                margin-bottom: 8px;

                .date-value{
                    color:var(--dark-blue);
                }
            }

        }

        .title-right{
            .svg-box{
                display:flex;
                align-items: center;
                gap: 6px;
                cursor: pointer;

                .svg-icon{
                    width: 12px;
                    height: 12px;
                }

                .arrow{
                    transform: rotate(-90deg);
                }
            }
        }
    }

    .box {
        display: grid;
        grid-template-columns: 30% 70%;

        .left {
            overflow-x: hidden;
            min-width: 320px;
            background: var(--bg-10);
            padding: 10px;
        }
    }

    .badage-box {
        display: flex;
        flex-wrap: wrap;

        .my-badges-iden {
            margin-right: 8px;
            margin-bottom: 4px;
        }
    }

    .remove-box{
        display: revert;
    }

    .flex{
        display:flex;
        align-items: center;
    }


}
</style>