<template>
    <div class="social-list-content">

        <Loading class="static" v-if="(post.view_value_loading && !is_social_head) || start_loading" />
        <div class="sticker-big-box" v-if="!post.view_value_loading && is_social_head && !start_loading"
            :key="is_social_head">
            <div class="sticker-tab-box-spec">
                <Button type="button" color="dark-blue" size="40" v-for="(v, k) in sticker_tab" :key="k" v-show="v.show"
                    :style="'no-border-square'" @click="clickTab(v.index)" :class="{ 'actived': now_tab === v.index }">
                    <svg-icon v-if="v.svg" :name="v.svg" />
                    <div class="right">
                        <div class="r-top">{{ v.status === 0 ? v.value :v.name }}</div>
                        <div v-if="v.status !== 0" :class="['han' , `class-${v.status}`]">{{ v.value }}</div>
                        <div v-else :class="['han' , `class-${v.status}`]">Not Connected</div>
                    </div>
                </Button>
            </div>
        </div>
        <time>Updated: {{ last_update_date }}</time>
        <div class="main">
            <div class="main-box">
                <Loading class="static" v-if="(post.loading && !is_social_head) || start_loading" />
                <div class="title on-top-title">
                    <h2>Social Insights</h2>
                    <p class="small-text">Insights available 7 days after content posted. Data updated daily after.</p>
                </div>

                <div class="title-list-box" v-if="!post.loading && is_social_head && !start_loading"
                    :key="is_social_head">
                    <div class="title"></div>
                </div>

                <!-- Statistic -->
                <Loading class="static" v-if="post.view_value_loading || start_loading" />

                <div class="ig-view" v-if="now_tab === 0 && !start_loading && !post.view_value_loading">
                    <div class="title-list-box">
                        <div class="title">In-Feed ({{ instagram_posts_count }} total)</div>
                        <time>Last 10 Posts</time>
                    </div>
                    <ValueBox :key="post.view_value_loading" :view_value="igVeiwValue.feed" />
                    <div class="title-list-box title-list-box-last">
                        <div class="title">Stories ({{ story_count }} total)</div>
                        <time>Last 30 Days</time>
                    </div>
                    <ValueBox :key="post.view_value_loading" :view_value="igVeiwValue.story" />
                </div>

                <div class="tk-view" v-if="now_tab === 1 && !start_loading && !post.view_value_loading">
                    <div class="title-list-box">
                        <div class="title">Video ({{ tiktok_posts_count }} total)</div>
                        <time>Last 10 Posts</time>
                    </div>
                    <ValueBox :show="!post.view_value_loading && !start_loading" :key="post.view_value_loading"
                        :view_value="post.tk_view_value" />
                </div>


            </div>
            <!-- && !no_audience -->
            <div class="main-box" v-if="!post.view_value_loading && !start_loading">
                <Audience :is_one_line="true" :title="now_tab === 0 ? 'Audience' : 'Reached Audience'"
                    :is_one_audience="true" :data="audience_data" />
            </div>

            <!-- top 10 post -->
            <TopBrandPost v-if="!start_loading" :is_hide_tag="is_app"
                :title="now_tab === 0 && post.ig_post_type === 2 ? 'Last 30 Days Posts' : 'Most Recent 10 Posts'"
                :posts="post.data" :posts_value="post" @clickPost="clickPost" :had_navi="false" @updateSort="updateSort"
                @updateTag="updateTag" @callApiAgain="callApiAgain" :selected_tags="selected_tags"
                :tag_text="default_text" :no_plus="true" :is_soc="true" @changeIgTag="changeIgTag" :now_tab="now_tab"
                :ambassador_user_application_record_id="ambassador_user_application_record_id" />

        </div>
    </div>
</template>

<script>
import Loading from '@/components/ui/Loading.vue';
import NoData from '@/components/creator/NoData.vue';
import moment from 'moment';
import DatePicker from '@/components/ui/DatePicker.vue';
import Dropdown from '@/components/ui/Dropdown.vue';
import Audience from '@/components/Audience.vue';
import CreatorSmallCard from '@/components/CreatorSmallCard.vue';
import Button from '@/components/ui/Button.vue';
import TopBrandPost from '@/components/TopBrandPost.vue';
import ValueBox from '@/components/ui/SocialValueBox.vue';
import { HandleNum, HandleName, HandleNumSpec, HandleKNum } from '@/utils/SwitchFun';
import { SwitchTopSort, get_all_post_data } from '@/utils/ContentSwitchFun.js';

export default {
    name: 'Collections-List',

    data() {
        return {
            start_date: moment().add(-30, 'days').format('YYYY-MM-DD'),//後端現在沒有用到
            end_date: moment().add(-1, 'days').format('YYYY-MM-DD'),//後端現在沒有用到
            sticker_tab: [
                { name: 'Instagram', index: 0, svg: 'instagram', show: false, value: '' },
                { name: 'TikTok', index: 1, svg: 'tiktok-pure', show: false, value: '' },
            ],
            is_social_head: false,
            now_tab: 0,
            now_user: '',
            user_select: [],
            now_user: 0,
            post: JSON.parse(JSON.stringify(get_all_post_data)),
            no_state: true,
            no_audience: true,
            audience_data: {
                gender: [],
                age: [],
                countries: []
            },
            otherTag: {
                hastags: [],
                instagram_mentions:[],
                tiktok_mentions:[],
            },
            default_text: 'Tags: All',
            tags: [],
            selected_tags: [],
            start_loading: false,
            last_update_date: '---',
            igVeiwValue: {
                feed: [],
                story: []
            },
            tiktok_posts_count: 0,
            instagram_posts_count: 0,
            story_count: 0,
            is_end:true,
        }
    },

    props: {
        data_info: {
            type: Object,
            default: null,
        },
        is_app: {
            type: Boolean,
            default: false,
        },
        ambassador_user_application_record_id: {
            type: Number,
            default: 0,
        },
        
        // last_update: {
        //     type: String,
        //     default: '',
        // },
        // user_data: {
        //     type: Object,
        //     default: {},
        // },
    },

    computed: {
        socisl_post_type() {
            //video reel post story
            if (this.now_tab === 0) {
                if (this.post.ig_post_type === 0) {
                    return 'post'
                } else if (this.post.ig_post_type === 1) {
                    return 'reel'
                } else {
                    return 'story'
                }
            } else {
                return 'video'
            }
        },
    },

    components: {
        NoData,
        Loading,
        DatePicker,
        Dropdown,
        Audience,
        CreatorSmallCard,
        Button,
        TopBrandPost,
        ValueBox,
    },

    watch: {
        data_info: {
            handler(val) {
                if (val) {
                    this.handleHan();
                }
            },
            deep: true
        }
    },

    methods: {
        handleNum: HandleNum,
        handleName: HandleName,
        HandleKNum: HandleKNum,

        handle_post_type(){
            if (this.now_tab === 0) {
                if (this.post.ig_post_type === 0) {
                    return 'post'
                } else if (this.post.ig_post_type === 1) {
                    return 'reel'
                } else {
                    return 'story'
                }
            } else {
                return 'video'
            }
        },

        daysAgo(date) {
            return formatDateDaysAgo(date);
        },

        getTags() {

            this.$ajax(`/creator/get-social-post-filter`, {
                method: 'get',
            }).then(res => {

                const data = res.data.data;
                if (!data) return;
                this.otherTag.hastags = data.social_post_hashtag_texts;
                this.otherTag.instagram_mentions= data.social_post_instagram_mention_texts;
                this.otherTag.tiktok_mentions=data.social_post_tiktok_mention_texts;


            }).catch(err => {
                console.log(err)
            })

        },

        clickTab(v) {
            this.now_tab = v;
            this.getStateData();
            this.getTopData();
        },

        callApiAgain() {
            this.getTopData();
        },


        updateSort(id) {
            let getVal = SwitchTopSort(id.id);
            this.post.sort.sort_key = getVal.key;
            this.post.sort.sort_val = getVal.val;
            this.post.sort_index = this.post.sort_select.findIndex(obj => obj.id === id.id);
            this.post.drop_key += 1;
            this.getTopData(false);
        },

        updateTag(tag) {
            if (tag === false) {
                this.selected_tags = [];
            } else if (this.selected_tags.findIndex(o => o.id === tag.id) === -1) {
                this.selected_tags.push(tag);
            } else {
                this.selected_tags = this.selected_tags.filter(o => o.id !== tag.id);
            }

            if (this.selected_tags.length === 0) {
                this.default_text = 'Tags: All';
            } else {
                this.default_text = 'Tags: ';
                this.selected_tags.forEach((v, k) => {
                    this.default_text += v.text + (k === this.selected_tags.length - 1 ? '' : ', ');
                });
            }

            this.getTopData(false)
        },

        reqParams() {
            return {
                anchor_creator_id: this.$store.state.pick_creator_user_id,
                merchant_account_id: this.$store.state.user_data.merchant_account_id,
                start_date: this.start_date,
                end_date: this.end_date,
                social_platform: this.now_tab === 1 ? 'tikTok' : 'instagram ',
            }
        },

        handleHan() {
            // console.log('this.data_info',this.data_info, this.sticker_tab)
            if (this.data_info && this.data_info.instagram_handle) {
                this.sticker_tab[0].show = true;
                this.sticker_tab[0].value = this.data_info.instagram_handle;
                this.sticker_tab[0].status = this.data_info.instagram_connect_status;
                this.sticker_tab[0].name = this.data_info.instagram_follower_count ? HandleKNum(Number(this.data_info.instagram_follower_count)) : '---';
            }

            if (this.data_info && this.data_info.tiktok_handle) {
                this.sticker_tab[1].show = true;
                this.sticker_tab[1].value = this.data_info.tiktok_handle;
                this.sticker_tab[1].status = this.data_info.tiktok_connect_status;
                this.sticker_tab[1].name = this.data_info.tiktok_follower_count ? HandleKNum(Number(this.data_info.tiktok_follower_count)) : '---'

            }

            this.is_social_head = true;
        },

        getStateData() {
            this.post.view_value_loading = true;
            let req_par = this.reqParams();
            let params_data = {
                ...req_par,
            }
            let api = `creator/get-social-account-posts-statistic`;

            if (this.is_app) {
                api = `/ambassador/get-application-user-record-detail/social-account-post-statistic`;

                params_data = {
                    ambassador_user_application_record_id: this.data_info.id,
                    social_platform: this.now_tab === 1 ? 'tikTok' : 'instagram',
                }
            }

            return new Promise((resolve, reject) => {
                this.$ajax(api, {
                    params: params_data,
                    method: 'get',
                }).then(res => {
                    let get_value = res.data.data;
                    this.last_update_date = get_value.social_platform_stats.last_updated_at ? this.$filters.momentFormat(get_value.social_platform_stats.last_updated_at, 'll') : '---';
                    this.igVeiwValue.feed = JSON.parse(JSON.stringify(this.post.ig_view_value));
                    this.igVeiwValue.story = JSON.parse(JSON.stringify(this.post.ig_view_value));

                    const pushValue = (arr, val) => {
                        arr.forEach(item => {
                            if (item.field === 'engagement_rate') {
                                item.value = HandleNumSpec(val[item.field]) !== '---' ? `${HandleNumSpec(val[item.field])}%` : HandleNumSpec(val[item.field]);
                            } else {
                                item.value = this.handleNum(val[item.field]);
                            }

                        });
                    }

                    if (this.now_tab === 0) {
                        pushValue(this.igVeiwValue.feed, get_value.social_platform_stats.stats[0]);
                        pushValue(this.igVeiwValue.story, get_value.social_platform_stats.stats[1]);
                        this.instagram_posts_count = get_value.social_platform_stats.stats[0].total;
                        this.story_count = get_value.social_platform_stats.stats[1].total;
                    } else {
                        pushValue(this.post.tk_view_value, get_value.social_platform_stats.stats[0]);
                        this.tiktok_posts_count = get_value.social_platform_stats.stats[0].total;
                    }

                    this.no_state = false;//this.post.view_value.every(e => e.value === '---' || e.value === '0%');
                    //audience
                    this.audience_data = {
                        gender: get_value.audience_gender.filter((e, k) => { return k < 10 }),
                        age: get_value.audience_age.filter((e, k) => { return k < 10 }),
                        countries: get_value.audience_countries.filter((e, k) => { return k < 10 }),
                    }
                    this.no_audience = get_value.audience_gender.length === 0 && get_value.audience_age.length === 0 && get_value.audience_countries.length === 0

                    this.post.view_value_loading = false;
                    resolve(true);
                }).catch(err => {
                    console.log(err)
                    resolve(true);
                    this.post.view_value_loading = false;
                })
            })

        },

        getTopData(is_next = false) {
            this.post.loading = true;
            let req_par = this.reqParams();

            if (is_next) {
                this.post.sort.page += 1;
            } else {
                this.post.sort.page = 1;
            }

            let api = `creator/get-social-account-posts`;

            let params_data = {
                ...req_par,
                sort_key: this.post.sort.sort_key,
                sort_val: this.post.sort.sort_val,
                page: this.post.sort.page,
                per_page: this.post.sort.per_page,
                with_extra_count: '',
                social_insight_only: 1,
                social_post_type: this.handle_post_type(),//this.socisl_post_type,
            }

            if (this.selected_tags.length > 0) {
                params_data.tag_ids = this.selected_tags.map(e => { return e.id })
            }

            if (this.is_app) {
                api = `ambassador/get-application-user-record-detail/social-account-posts`;
                params_data = {
                    ambassador_user_application_record_id: this.data_info.id,
                    social_platform: this.now_tab === 1 ? 'tikTok' : 'instagram',
                    social_post_type: this.handle_post_type(),//this.socisl_post_type,
                    page: this.post.sort.page, // this parameter is required
                }
            }

            return new Promise((resolve, reject) => {
                this.$ajax(api, {
                    params: params_data,
                    method: 'get',
                }).then(res => {
                    // console.log(res.data.data)
                    let getV = res.data.data;
                    if ( this.handle_post_type() === 'story') {
                        //這邊只有story可以CALL到下一頁
                        this.is_end = getV.social_posts.last_page <= getV.social_posts.current_page;
                        if (is_next) {
                            this.post.data.push(...getV.social_posts.data);
                        } else {
                            this.post.data = getV.social_posts.data;
                        }

                    } else {
                        this.is_end = true;
                        this.post.data = getV.social_posts.data.filter((e, k) => {
                            return k < 10
                        });
                    }

                    const getCountForPostCategory = (field) => {
                        for (let item of getV.social_post_category) {
                            if (item.social_post_category === field) {
                                return item.count;
                            }
                        }
                        return 0;
                    }

                    this.post.ig_menu[0].value = getCountForPostCategory('post');
                    this.post.ig_menu[1].value = getCountForPostCategory('reel');
                    this.post.ig_menu[2].value = getCountForPostCategory('story');
                    this.post.loading = false;

                    if (!this.is_end) {
                        this.getTopData(true);
                    }else{
                        resolve(this.post.data);
                    }

                }).catch(err => {
                    resolve([]);
                    this.post.loading = false;
                })
            })
        },

        changeIgTag(index) {
            this.post.ig_post_type = index;
            this.getTopData(false);
        },

        clickPost(v) {

        },

        goSetting() {
            window.location.href = '/settings/content-listening';
        },

    },

    async mounted() {
        // this.getFilterList();
        this.handleHan();
        //---------------------------------------------------------------------------------------
        //If IG have no data, but TK does, default should show TK tab
        //---------------------------------------------------------------------------------------
        try {
            this.start_loading = true;
            let result1 = await this.getTopData();
            let result2 = await this.getStateData();

            if (result1.length === 0 && result2 && (this.now_tab === 0 && this.data_info && this.data_info.tiktok_handle)) {
                this.now_tab = 1;
                console.log('ig-no-data')
                let result1 = await this.getTopData();
                let result2 = await this.getStateData();
                //---------------------------------------------------------------------------------------
                //If TK have no data, back to IG tab
                //---------------------------------------------------------------------------------------
                if (result1.length === 0 && result2 && this.data_info && this.data_info.instagram_handle) {
                    console.log('tk-no-data-too')
                    this.now_tab = 0;
                    this.getTopData();
                    this.getStateData();
                }
            }
            this.start_loading = false;
        } catch (error) {
            this.start_loading = false;
            console.error('Error:', error);
        }

    },

}
</script>

<style lang="scss">
@mixin textOver() {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
}

@mixin flexCenter() {
    display: flex;
    align-items: center;
}

.social-list-content {
    padding: 24px 0;

    .link {
        color: var(--blue);
        cursor: pointer;
    }

    .sticker-tab-box-spec {
        @include flexCenter();
        margin-top: 0px; //24px;
        margin-bottom: 16px;

        .ui-button {
            margin-right: 16px;
            border-radius: 6px !important;
            display: inline-flex;
            align-items: center;
            line-height: 1;
            box-sizing: border-box;
            text-wrap: nowrap;
            padding: 20px 40px;
            line-height: unset;
            height: unset;
            border: 1px solid var(--dark-blue);
            font: var(--font-fg-24);
        
            .r-top{
                width: 100%;
                text-align: left;
            }

            .han {
                font: var(--font-fg-14);
                display: flex;
                align-items:center;
                float: left;

                &::after {
                    height: 8px;
                    width: 8px;
                    content: "";
                    display: block;
                    border-radius: 50%; /* 完整圓形 */
                    background: linear-gradient(to right, var(--yellow) 50%, transparent 50%); /* 左半邊填充顏色 */
                    border: 1px solid var(--yellow); /* 整個圓的邊框 */
                    margin-left: 6px;
                }

                //fully connected 
                &.class-20{
                    &::after {
                        background: var(--green);
                        border: 1px solid var(--green);
                    }
                }

                //partial connected
                &.class-10{
                    &::after {
                        background: linear-gradient(to right, var(--green) 50%, transparent 50%); /* 左半邊填充顏色 */
                        border: 1px solid var(--green); /* 整個圓的邊框 */
                    }
                }

                //fully connected expired
                &.class-25{
                    &::after {
                        background:var(--alert);
                        border: 1px solid var(--alert);
                    }
                }

                //partial connected expired
                &.class-15{
                    &::after {
                        background: linear-gradient(to right, var(--alert) 50%, transparent 50%); /* 左半邊填充顏色 */
                        border: 1px solid var(--alert); /* 整個圓的邊框 */
                    }
                }

                //not connected
                &.class-0{
                    display: inline-flex;
                    font: var(--font-fg-12);
                    border:solid 1px var(--neutral);
                    border-radius: 20px;
                    padding: 4px 8px;
                    margin-top: 8px;

                    &::after{
                        display: none;
                    }

                    &::before {
                        height: 8px;
                        width: 8px;
                        content: "";
                        display: block;
                        border-radius: 50%; /* 完整圓形 */
                        margin-left: 0;
                        margin-right: 6px; 
                        background: var(--neutral); 
                        border: 1px solid var(--neutral); /* 整個圓的邊框 */
                    }
                }

            }

            &:hover {
                background-color: var(--bg-02);
                border-color: var(--bg-02) !important;
            }

            &.actived,
            &.actived:hover {
                color: var(--white);
                background-color: var(--dark-blue);

                svg {
                    fill: var(--white);
                }
            }


            svg {
                margin-right: 12px;
                height: 45px;
                width: 45px;
            }
        }

    }

    .main {
        margin-top: 16px;
    }

    .on-top-title {
        h2 {
            font: var(--font-demi-20);
            color: var(--black-90);
            margin: 0;
            flex: 1;
        }

        .small-text {
            font-family: var(--font);
            font-size: 12px;
            color: var(--black-75);
            margin-top: 8px;
        }

    }

    .sticker-big-box {
        @include flexCenter();
        justify-content: space-between;

        .sticker-tab-box {
            margin-top: 0;
        }
    }

    .main {
        height: calc(100% - 120px);

        .main-box {
            padding: 16px 12px;
            border-radius: 8px;
            margin-bottom: 16px;
            background-color: var(--light-neutral);
        }

        .title {
            font: var(--font-demi-20);
        }

        .title-list-box {
            @include flexCenter();
            margin-bottom: 8px;
            justify-content: space-between;

            .title {
                margin-top: 0px;
                font: var(--font-med-16);
                color: var(--black-90);
            }

            time {
                font: var(--font-fg-14);
                color: var(--black-75);
            }

            .handle-drop {
                .ui-button {
                    border: unset;
                    background-color: unset;
                    justify-content: flex-start;
                    padding: 0 !important;

                    span {
                        font: var(--font-demi-20);
                        color: var(--black-90);
                    }
                }
            }

        }

        .report-box {
            grid-template-columns: repeat(3, 1fr);
        }

        .ig-view {

            .title-list-box-last {
                margin-top: 12px;
            }
        }

        .top-brand-post-box {
            background-color: var(--light-neutral);

            .title {
                padding: 16px 12px 0;
            }

            .tab-box {
                margin-top: -28px;
                padding: 0 12px;
                margin-bottom: unset;
            }
        }
    }

}
</style>