const Sort_select_top_list = [
    { id: 5, text: 'Sort: Created (Newest)', only_ig: false, },
    { id: 6, text: 'Sort: Created (Oldest)', only_ig: false, },

    { id: 1, text: 'Sort: Engagements (Descending)', only_ig: false, },
    { id: 2, text: 'Sort: Engagements (Ascending)', only_ig: false, },

    { id: 7, text: 'Sort: Engagement Rate (Descending)', only_ig: false, },
    { id: 8, text: 'Sort: Engagement Rate (Ascending)', only_ig: false, },

    { id: 3, text: 'Sort: Views (Descending)', only_ig: false, },
    { id: 4, text: 'Sort: Views (Ascending)', only_ig: false, },

];


const SwitchTopSort = (type) => {
    let ths_sort = {
        key: '',
        val: ''
    };
    switch (type) {
        case 1:
            ths_sort.key = 'engagement';
            ths_sort.val = 'desc';
            break;
        case 2:
            ths_sort.key = 'engagement';
            ths_sort.val = 'asc';
            break;

        case 3:
            ths_sort.key = 'views';
            ths_sort.val = 'desc';
            break;

        case 4:
            ths_sort.key = 'views';
            ths_sort.val = 'asc';
            break;

        case 5:
            ths_sort.key = 'created_at';
            ths_sort.val = 'desc';
            break;

        case 6:
            ths_sort.key = 'created_at';
            ths_sort.val = 'asc';
            break;

        case 7:
            ths_sort.key = 'engagement_rate';
            ths_sort.val = 'desc';
            break;

        case 8:
            ths_sort.key = 'engagement_rate';
            ths_sort.val = 'asc';
            break;

        default:
            break;
    }

    return ths_sort;
};

const get_all_post_data = {
    loading: true,
    next_loading:false,
    sort_select: Sort_select_top_list,
    top_menu: [
        { text: 'TikTok' },
        { text: 'Instagram' },
    ],
    ig_menu: [
        { text: 'Posts', value: 0, index: 0 },
        { text: 'Reels', value: 0, index: 1 },
        { text: 'Stories', value: 0, index: 2 },
    ],
    top_menu_index: 0,
    tag_loading: true,
    tag_select: [
        { id: 0, text: 'All', selected: true },
    ],
    sort_index: 0,
    sort: {
        sort_key: 'created_at',
        sort_val: 'desc',
        tag_val: 'all',
        page: 1,
        per_page: 50
    },
    drop_key: 0,
    tag_key: 0,
    view_value_loading: true,
    tk_view_value: [
        { name: 'Views', value: '---', detail: '', field: 'views', content: `TikTok views are counted when a video plays, including autoplays and loops.`, tip: 'viewTip', show: true },
        { name: 'Engagements', value: '---', detail: '', field: 'engagement', tip: 'engTip', content: '', show: true },
        { name: 'Engagement Rate', value: '---', detail: '', field: 'engagement_rate', tip: 'postTip', content: 'Total number of reactions, comments, and saves during the last 30 days / number of views. This may include removed or deleted reactions and comments.', show: true },
    ],
    ig_view_value: [
        { name: 'Impressions', value: '---', detail: '', field: 'impressions', tip: 'followrTip', content: 'Total Instagram views for the the last 30 days. Instagram views are counted when a video is watched for 3+ seconds and does not include video loops.', show: true },
        { name: 'Engagements', value: '---', detail: '', field: 'engagement', content: '', tip: 'viewTip', show: true },
        { name: 'Engagement Rate', value: '---', detail: '', field: 'engagement_rate', tip: 'engTip', content: 'Total number of reactions, comments, and saves during the last 30 days / number of views. This may include removed or deleted reactions and comments.', show: true },
    ],
    all_data: [],
    ig_post_type: 0,
    data: [],
    data_key: 0,

}

const handleSelectText = (arr) => {
    let tag_text = '';
    if (arr && arr[0]) {

        if (arr && arr[0] && arr[0].selected) {
            tag_text = 'Tag:All';
        } else {
            const clickedTags = arr.filter(tag => tag.selected).map(tag => tag.text);

            if (clickedTags.length > 0) {
                tag_text = 'Tag:' + clickedTags.join(', ');
            } else {
                tag_text = 'Tag:None';
            }
        }
    }
    return tag_text;
}

const handleSelectArray = (arr, sel_arr, org_arr) => {
    let existingTagIndex = sel_arr.findIndex(tag => tag.id === arr.id);

    if (existingTagIndex !== -1) {
        sel_arr.splice(existingTagIndex, 1);
    } else {
        sel_arr.push(arr);
    }

    if (sel_arr.length > 1) {
        let existingTagIndex = sel_arr.findIndex(tag => tag.id === 0);
        if (existingTagIndex !== -1) {
            sel_arr.splice(existingTagIndex, 1);
        }
    }

    if (arr.id === 0) {
        sel_arr = [];
        sel_arr.push(arr);
    }
    // if (sel_arr.length === org_arr.length - 1) {
    //     sel_arr.push({ id: 0, text: 'All', selected: true });
    // }
    return sel_arr;
}


const compareArrays = (arrA, arrB, fieldA, fieldB) => {
    for (let i = 0; i < arrA.length; i++) {
        for (let j = 0; j < arrB.length; j++) {
            if (arrA[i][fieldA] === arrB[j][fieldA]) {
                arrA[i][fieldB] = true;
                break;
            } else {
                arrA[i][fieldB] = false;
            }
        }
    }
    return arrA;
}

export {
    Sort_select_top_list,
    SwitchTopSort,
    get_all_post_data,
    handleSelectText,
    compareArrays,
    handleSelectArray,
};